import React from "react"
import LogoImage from "../images/logo/ministry-of-pleasure-logo-black-svg.svg"

const Footer = () => (
  <footer>
    <div className="footer-logo-wrapper">
        <div className="footer-logo">
            <img src={LogoImage} alt="Ministry of Pleasure logo" />
        </div>
    </div>
    <div className="copyright">
        <p>Copyright &copy; 2012-{new Date().getFullYear()} Orgasmic Ltd. All rights reserved.</p>
        <p><em>Ministry of Pleasure</em>&reg; is a Registered Trademark of Orgasmic Ltd.</p>
    </div>
        <div className="company-info">
            <p>Registered Office: Acorn House, Keys Park Road, Cannock, WS12 2FZ, United Kingdom.</p>
            <p>Registered in England and Wales 1999. Company Registration Number: 03844011.</p>
        </div>
    </footer>
)

export default Footer
